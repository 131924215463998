import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js'
import VueGoogleMaps from "@fawmi/vue-google-maps";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';

createApp(App)
    .use(router) // Use Vue Router
    .use(VueGoogleMaps, {
        load: {
          key: 'AIzaSyBC6I7ERSRGhSaQ-WDHuSDzLPu9-dJKw70',
        },
       })
    .mount('#app');